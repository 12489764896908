<template>
	<form action="#" class="settings-socials">
		<h2 class="section-title">Социальные сети</h2>
		<fieldset class="settings-socials__fieldset">
			<legend>
				Привязав социальные сети, Вы сможете получать уведомления о сообщениях в
				нужный мессенджер.
			</legend>
			<ul class="settings-socials__list">
				<li class="settings-socials__item">
					<label for="telegram-notification" class="text">Telegram</label>
					<div class="switcher">
						<input
							class="tgl tgl-light"
							id="telegram-notification"
							type="checkbox"
						/>
						<label class="tgl-btn" for="telegram-notification"></label>
					</div>
				</li>
				<li class="settings-socials__item">
					<label for="ok-notification" class="text">Одноклассники</label>
					<div class="switcher">
						<input class="tgl tgl-light" id="ok-notification" type="checkbox" />
						<label class="tgl-btn" for="ok-notification"></label>
					</div>
				</li>
				<li class="settings-socials__item">
					<label for="vk-notification" class="text">Вконтакте </label>
					<div class="switcher">
						<input class="tgl tgl-light" id="vk-notification" type="checkbox" />
						<label class="tgl-btn" for="vk-notification"></label>
					</div>
				</li>
			</ul>
		</fieldset>
	</form>
</template>

<script>
// import { inject, onMounted, ref } from 'vue'
// import { notify } from '@kyvg/vue3-notification'

export default {
	// setup() {
	// 	const api = inject('api')
	// 	const settings = ref({})

	// 	onMounted(async () => {
	// 		const response = await api.notification.get()

	// 		if (response.status) {
	// 			settings.value = response.response
	// 		}
	// 	})

	// 	const submit = async () => {
	// 		const response = await api.notification.update(settings.value)

	// 		if (response.status) {
	// 			notify({
	// 				type: 'success',
	// 				text: 'Данные найстроки уведомлений успешно обновлены'
	// 			})

	// 			return
	// 		}

	// 		console.error('Error update notification: ', response)
	// 	}

	// 	return {
	// 		settings,

	// 		submit
	// 	}
	// }
}
</script>

<style lang="sass" scoped>
.settings-socials
  &__fieldset
    margin-top: 30px
    margin-bottom: 15px
</style>